import AuthMiddleware from "@/middlewares/AuthMiddleware";
import { RouteRecordRaw } from "vue-router";
import ContractNotApprovedMiddleware from "@/middlewares/ContractNotApprovedMiddleware";

export const full_access: Array<RouteRecordRaw> = [
  {
    path: "",
    component: () => import(/* webpackChunkName: "full-access" */ "@/FullAccessApp.vue"),
    meta: {
      middleware: [AuthMiddleware],
    },
    children: [
      {
        path: "",
        name: "Home",
        component: () => import(/* webpackChunkName: "home" */ "@/views/Home/Home.vue"),
      },
      {
        path: "/education",
        name: "Education",
        component: () => import(/* webpackChunkName: "education" */ "@/views/Education/Education.vue"),
      },
      {
        path: "/appointments",
        name: "Appointments",
        component: () => import(/* webpackChunkName: "appointments" */ "@/views/Appointments/Appointments.vue"),
      },
      {
        path: "/preliminary-exam",
        name: "PreliminaryExam",
        component: () => import(/* webpackChunkName: "preliminaryExam" */ "@/views/PreliminaryExam/PreliminaryExam.vue"),
      },
      {
        path: "/preliminary-exam/:id",
        name: "FinishedPreliminaryExam",
        component: () => import(/* webpackChunkName: "finishedPreliminaryExam" */ "@/views/PreliminaryExam/FinishedPreliminaryExam.vue"),
      },
      {
        path: "/learn-world",
        name: "LearnWorld",
        component: () => import(/* webpackChunkName: "LearnWorld"*/ "@/views/LearnWorld/LearnWorld.vue"),
      },
      {
        path: "/abibaro",
        name: "Abibaro",
        component: () => import(/* webpackChunkName: "abibaro" */ "@/views/Abibaro/Abibaro.vue"),
      },
      {
        path: "/theory-questions/:studentEducation",
        name: "TheoryQuestions",
        component: () => import(/* webpackChunkName: "theoryQuestions" */ "@/views/TheoryQuestions/TheoryQuestions.vue"),
      },
      {
        path: "/theory-pre-exams/:studentEducation",
        name: "TheoryPreExams",
        component: () => import(/* webpackChunkName: "practice" */ "@/views/TheoryPreExams/TheoryPreExams.vue"),
      },
      {
        path: "/playground",
        name: "Playground",
        component: () => import(/* webpackChunkName: "playground" */ "@/views/Playground/Playground.vue"),
      },
      {
        path: "/themelooper",
        name: "ThemeLooper",
        component: () => import(/* webpackChunkName: "themelooper" */ "@/views/ThemeLooper/ThemeLooper.vue"),
        children: [
          {
            path: "",
            name: "ThemeLooperCategories",
            component: () => import(/* webpackChunkName: "CategoriesSubcategories" */ "@/views/ThemeLooper/CategoriesSubcategories.vue"),
          },
          {
            path: "G/:id",
            name: "BasicSubcategoryDetailed",
            component: () => import(/* webpackChunkName: "BasicSubcategoryDetailed" */ "@/views/ThemeLooper/SubcategoryDetailed.vue"),
          },
          {
            path: ":id",
            name: "ClassSubcategoryDetailed",
            component: () => import(/* webpackChunkName: "BasicSubcategoryDetailed" */ "@/views/ThemeLooper/SubcategoryDetailed.vue"),
          },
        ],
      },
      {
        path: "/most-difficult/:licenceClass/:question",
        name: "PlaygroundMostDifficult",
        component: () => import(/* webpackChunkName: "playground-most-difficult" */ "@/views/Playground/Questions/MostDifficult.vue"),
      },
      {
        path: "/theory-question/:id(\\\\d+)*",
        name: "PlaygroundTheoryQuestion",
        component: () => import(/* webpackChunkName: "playground-theory-question" */ "@/views/Playground/Questions/Question.vue"),
      },
      {
        path: "/theory-question-group/:id",
        name: "TheoryQuestionGroup",
        component: () => import(/* webpackChunkName: "playground-theory-question-group" */ "@/views/Playground/Questions/TheoryQuestionGroup.vue"),
      },
      {
        path: "/theory-question-box/:id/:studentEducation",
        name: "TheoryQuestionBoxes",
        component: () => import(/* webpackChunkName: "theory-question-box" */ "@/views/TheoryBoxes/TheoryQuestionBoxes.vue"),
      },
      {
        path: "/account",
        name: "Account",
        component: () => import(/* webpackChunkName: "account" */ "@/views/Account/Account.vue"),
      },
      {
        path: "/docs",
        name: "Docs",
        component: () => import(/* webpackChunkName: "docs" */ "@/views/Docs/Docs.vue"),
      },
      {
        path: "/news",
        name: "News",
        component: () => import(/* webpackChunkName: "news" */ "@/views/News/News.vue"),
      },
      {
        path: "/profile",
        name: "Profile",
        component: () => import(/* webpackChunkName: "profile" */ "@/views/Profile/Profile.vue"),
      },
      {
        path: "/set-up-mandate/success",
        name: "SetUpMandateSuccess",
        component: () => import(/* webpackChunkName: "setup-mandate-success" */ "@/views/Mandate/SetUpMandateSuccess.vue"),
      },
      {
        path: "/set-up-mandate/failure",
        name: "SetUpMandateFail",
        component: () => import(/* webpackChunkName: "setup-mandate-fail" */ "@/views/Mandate/SetUpMandateFail.vue"),
      },
      {
        path: "/top-up-account",
        name: "TopUpAccount",
        component: () => import(/* webpackChunkName: "top-up-account" */ "@/views/Account/TopUpAccount.vue"),
      },
      {
        path: "/report-problem",
        name: "TechnicalProblemView",
        component: () => import(/* webpackChunkName: "top-up-account" */ "@/views/TechnicalProblemReport/TechnicalProblemView.vue"),
      },
    ],
  },
  {
    path: "/training-contract/:drivingSchoolId",
    component: () => import(/* webpackChunkName: "training-contract" */ "@/views/Onboarding/TrainingContract/Index.vue"),
    meta: {
      middleware: [ContractNotApprovedMiddleware],
    },
    children: [
      {
        path: "congratulations",
        name: "Congratulations",
        component: () => import(/* webpackChunkName: "congratulations" */ "@/views/Onboarding/TrainingContract/Congratulations.vue"),
      },
      {
        path: "personal-data",
        name: "PersonalData",
        component: () => import(/* webpackChunkName: "personal-data" */ "@/views/Onboarding/TrainingContract/PersonalData.vue"),
      },
      {
        path: "report",
        name: "Report",
        component: () => import(/* webpackChunkName: "report" */ "@/views/Onboarding/TrainingContract/Report.vue"),
      },
    ],
  },
];
