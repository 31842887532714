import { State } from "./state";
import { GetterTree } from "vuex";

import { getDataItem, getDataList, getError, getId, getIsLoading, getSuccess, getTotal } from "@/store/Crud/functions/getters";

export const getIsCategorySet = (state: State) => {
  return state.dataList !== null && Object.keys(state.dataList).length > 0;
};

export const getBaseCategory = (state: State) => {
  return state.dataList?.find((item: any) => item.name === "basic") || null;
};

export const getClassCategories = (state: State) => {
  return state.dataList?.filter((item: any) => item.name !== "basic") || null;
};

export const getCurrentClassCategory = (state: State) => {
  return state.currentClassCategory;
};

export const getSubcategoryDetails = (state: State) => {
  return state.subCategory?.details;
};

export const getSubcategoryQuestions = (state: State) => {
  return state.subCategory?.questions;
};

export const getYoutubeLink = (state: State) => {
  return state.youtubeLink;
};

export default <GetterTree<State, any>>{
  getError,
  getIsLoading,
  getDataItem,
  getSuccess,
  getDataList,
  getTotal,
  getId,
  getIsCategorySet,
  getBaseCategory,
  getClassCategories,
  getSubcategoryDetails,
  getSubcategoryQuestions,
  getCurrentClassCategory,
  getYoutubeLink,
};
