import { ActionTree } from "vuex";
import { State } from "./state";
import { IS_LOADING, SET_DATA_LIST, SET_ERROR, SET_SUBCATEGORY_DATA, SET_YOUTUBE_LINK } from "@/store/ThemeLooper/types";
import { create, filter, findAll, findOne, update, getOne, resetSave } from "@/store/Crud/functions/actions";
import axios from "@/utils/axios";

const URL = "/theme-loop";

export const findCategoriesSubcategories = async (store: any): Promise<void> => {
  store.commit(IS_LOADING, true);
  return await axios
    .get(`${URL}/student-category-subcategory`)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_DATA_LIST, data);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
    });
};

export const findSubcategoriesDetailed = async (store: any, options: any): Promise<void> => {
  store.commit(IS_LOADING, true);
  return await axios
    .get(`${URL}/${options?.subCategoryOfficialNumber}/${options?.licenceClass}/detailed`)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_SUBCATEGORY_DATA, data);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
    });
};

export const findYoutubeLink = async (store: any): Promise<void> => {
  store.commit(IS_LOADING, true);
  return await axios
    .get(`${URL}/youtube-link`)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_YOUTUBE_LINK, data);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
    });
};

export default <ActionTree<State, any>>{
  create,
  filter,
  findOne,
  update,
  findAll,
  getOne,
  resetSave,
  findCategoriesSubcategories,
  findSubcategoriesDetailed,
  findYoutubeLink,
};
