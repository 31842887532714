import { State } from "./state";
import { MutationTree } from "vuex";
import { IS_LOADING, SET_DATA_ID, SET_DATA_ITEM, SET_DATA_LIST, SET_ERROR, SET_SUCCESS, SET_TOTAL } from "@/store/Crud/functions/mutations";

export function SET_SUBCATEGORY_DATA(state: State, data: any) {
  state.subCategory = data;
}

export function SET_CURRENT_CLASS_CATEGORY(state: State, data: any) {
  state.currentClassCategory = data;
}

export function SET_YOUTUBE_LINK(state: State, data: any) {
  state.youtubeLink = data;
}

export default <MutationTree<State>>{
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_ID,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_SUBCATEGORY_DATA,
  SET_CURRENT_CLASS_CATEGORY,
  SET_YOUTUBE_LINK,
};
